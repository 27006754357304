import React from "react";
import { Divider } from "@material-ui/core";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import { RichText, Date } from "prismic-reactjs";
import Layout from "../components/layout/index";
import SEO from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const ListThumbnail = [
  {
    uid: "design-from-scratch-for-app-kuu",
    image: (
      <StaticImage
        placeholder="blurred"
        quality="40"
        src="../images/Mobile/ThumbnailTutorial/Thumbnail01.png"
        alt=""
        width={200}
      />
    ),
  },
  {
    uid: "use-inspiration-templates-for-app-kuu",
    image: (
      <StaticImage
        placeholder="blurred"
        quality="40"
        src="../images/Mobile/ThumbnailTutorial/Thumbnail02.png"
        alt=""
        width={200}
      />
    ),
  },
  {
    uid: "remove-background-for-app-kuu",
    image: (
      <StaticImage
        placeholder="blurred"
        quality="40"
        src="../images/Mobile/ThumbnailTutorial/Thumbnail03.png"
        alt=""
        width={200}
      />
    ),
  },
  {
    uid: "use-search-feature-like-a-pro-for-app-kuu",
    image: (
      <StaticImage
        placeholder="blurred"
        quality="40"
        src="../images/Mobile/ThumbnailTutorial/Thumbnail04.png"
        alt=""
        width={200}
      />
    ),
  },
  {
    uid: "create-outstanding-animation-at-your-fingertips-in",
    image: (
      <StaticImage
        placeholder="blurred"
        quality="40"
        src="../images/Mobile/ThumbnailTutorial/Thumbnail05.png"
        alt=""
        width={200}
      />
    ),
  },
  {
    uid: "change-face-ai",
    image: (
      <StaticImage
        placeholder="blurred"
        quality="40"
        src="../images/Mobile/ThumbnailTutorial/Thumbnail06.png"
        alt=""
        width={200}
      />
    ),
  },
];

const CardWrapper = styled.div`
  background-color: #fff;
  box-shadow: rgb(17 17 26 / 5%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 0px 8px;
  border-radius: 0.5em;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  font-family: "Nunito Sans";

  .thumbnail-container {
    padding: 1em;
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 1em;
  }
  .textBox-container {
    display: grid;
    gap: 0.5em;
  }
  .description-text {
    font-size: clamp(0.9rem, 2.5vw, 0.9rem);
    color: rgb(64, 64, 64);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
const StyledLink = styled(Link)`
  font-size: clamp(1.1rem, 2.5vw, 1.2rem);
  color: #000;
  font-style: "Nunito Sans";
  font-weight: 600;
  text-decoration: none;
  :hover {
    color: #fbb400;
    text-decoration: underline;
  }
`;

const ContainerUserGuide = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  gap: 0.5em;
`;

const ContainerTutorial = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  gap: 1.2em;
`;

const Help = (props) => {
  const {
    data: { postsTutorials, postsUserGuide },
  } = props;
  const { t } = useTranslation();

  const tutorialsThumbnail = [
    ...postsTutorials.nodes.reduce((acc, cur) => {
      const thumbnail = ListThumbnail.find((e) => e.uid === cur.uid);
      return [
        ...acc,
        {
          ...cur,
          name: thumbnail !== undefined ? thumbnail.uid : "",
          imageElement: thumbnail !== undefined ? thumbnail.image : "",
        },
      ];
    }, []),
  ];

  return (
    <Layout>
      <SEO title="How to use" />
      <div
        style={{
          maxWidth: 900,
          margin: "0 auto",
        }}
      >
        {postsTutorials.nodes.length !== 0 && (
          <>
            <div
              style={{
                padding: "0.6em 0.4em",
                fontSize: "1.5rem",
                fontWeight: 600,
                borderRadius: 4,
                textTransform: "capitalize",
              }}
            >
              <strong style={{ marginRight: "0.4em" }}>
                {t("footer.Tutorials")}{" "}
              </strong>
            </div>
            <ContainerTutorial>
              {tutorialsThumbnail.map((object) => (
                <CardWrapper key={object.id}>
                  <div className="thumbnail-container">
                    <div>
                      {object.imageElement !== "" && object.imageElement}
                    </div>
                    <div className="textBox-container">
                      <StyledLink to={`/mobile/${object.uid}`}>
                        {RichText.asText(object.data.title.raw).length !== 0 &&
                          RichText.asText(object.data.title.raw)}
                      </StyledLink>

                      <div className="description-text">
                        {RichText.asText(object.data.description.raw).length !==
                          0 && RichText.asText(object.data.description.raw)}
                      </div>

                      <div
                        style={{
                          color: "rgba(117, 117, 117, 1)",
                          padding: "0.3em 0em",
                          fontSize: "0.85rem",
                        }}
                      >
                        <time>
                          {new Intl.DateTimeFormat("en-US", {
                            month: "short",
                            day: "2-digit",
                            year: "numeric",
                          }).format(Date(object.data.date))}
                        </time>
                      </div>
                    </div>
                  </div>
                </CardWrapper>
              ))}
            </ContainerTutorial>

            <Divider
              variant="middle"
              style={{
                marginBottom: "1em",
                marginTop: "1.5em",
              }}
            />
          </>
        )}

        {postsUserGuide.nodes.length !== 0 && (
          <>
            <div
              style={{
                padding: "0.6em 0.4em",
                fontSize: "1.5rem",
                fontWeight: 600,
                borderRadius: 4,
                textTransform: "capitalize",
              }}
            >
              <strong style={{ marginRight: "0.4em" }}>
                {" "}
                {t("footer.User Guide")}
              </strong>
            </div>
            <ContainerUserGuide>
              {postsUserGuide.nodes.map((object) => (
                <CardWrapper key={object.id}>
                  <div
                    style={{
                      padding: "1.5em",
                      width: "100%",
                      height: "100%",
                      display: "grid",
                      gap: "0.5em",
                    }}
                  >
                    <StyledLink to={`/mobile/${object.uid}`}>
                      {RichText.asText(object.data.title.raw).length !== 0 &&
                        RichText.asText(object.data.title.raw)}
                    </StyledLink>

                    <div
                      style={{
                        fontSize: "clamp(0.9rem, 2.5vw, 0.9rem)",
                        color: "rgb(64 ,64 ,64)",
                      }}
                    >
                      {RichText.asText(object.data.description.raw).length !==
                        0 && RichText.asText(object.data.description.raw)}
                    </div>

                    <div
                      style={{
                        color: "rgba(117, 117, 117, 1)",
                        padding: "0.3em 0em",
                        fontSize: "0.85rem",
                      }}
                    >
                      <time>
                        {new Intl.DateTimeFormat("en-US", {
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                        }).format(Date(object.data.date))}
                      </time>
                    </div>
                  </div>
                </CardWrapper>
              ))}
            </ContainerUserGuide>
            <Divider
              variant="middle"
              style={{
                marginBottom: "1em",
                marginTop: "1.5em",
              }}
            />
          </>
        )}
      </div>
    </Layout>
  );
};
export default Help;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    postsTutorials: allPrismicPostApp(
      filter: {
        data: {
          categories: {
            elemMatch: {
              category: { lang: { eq: $language }, slug: { eq: "tutorials" } }
            }
          }
        }
      }
    ) {
      nodes {
        id
        uid
        url
        lang
        data {
          title {
            raw
          }
          description {
            raw
          }
          date
          categories {
            category {
              id
              document {
                ... on PrismicCategory {
                  data {
                    name
                  }
                }
              }
            }
          }
          body {
            ... on PrismicPostAppBodyBannerWithCaption {
              id
              slice_label
              slice_type
              primary {
                image_banner {
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
                description {
                  raw
                }
              }
            }
          }
        }
      }
    }
    postsUserGuide: allPrismicPostApp(
      sort: { fields: [last_publication_date], order: DESC }
      filter: {
        data: {
          categories: {
            elemMatch: {
              category: { lang: { eq: $language }, slug: { eq: "user-guide" } }
            }
          }
        }
      }
    ) {
      nodes {
        id
        uid
        url
        lang
        data {
          title {
            raw
          }
          description {
            raw
          }
          date
          categories {
            category {
              id
              document {
                ... on PrismicCategory {
                  data {
                    name
                  }
                }
              }
            }
          }
          body {
            ... on PrismicPostAppBodyBannerWithCaption {
              id
              slice_label
              slice_type
              primary {
                image_banner {
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
                description {
                  raw
                }
              }
            }
          }
        }
      }
    }
  }
`;
